<template>
    <div class="rankingList2">
        <!-- <title-two url="/newHome" title="排行榜"/> -->
		<common-head url="/newHome" :is-return="true" style="background-image: none;"></common-head>
		
		<div class="rankTop">
			<div class="item" v-for="(item, index) in rankList" v-if="index == 1" :key="index">
				<div class="icon">
					<img class="avatar" src="../../../public/img/logo.jpeg" alt="" />
					<img class="mask" src="@/assets/imgs/rank/rankinglist_ranking2.png">
				</div>
			    <div class="userName">{{ item.username }}</div>
			</div>
			<div class="item first" v-for="(item, index) in rankList" v-if="index == 0" :key="index">
				<div class="icon">
				    <img class="mask" src="@/assets/imgs/rank/rankinglist_ranking1.png">
					<img class="avatar" src="../../../public/img/logo.jpeg" alt="" />
				</div>
			    <div class="userName">{{ item.username }}</div>
			</div>
			<div class="item" v-for="(item, index) in rankList" v-if="index == 2" :key="index">
				<div class="icon">
				    <img class="mask" src="@/assets/imgs/rank/rankinglist_ranking3.png">
					<img class="avatar" src="../../../public/img/logo.jpeg" alt="" />
				</div>
			    <div class="userName">{{ item.username }}</div>
			</div>
		</div>
		
		<div class="layoutBox bgWhite">
			<div class="rankInfo">
				<h3>{{ $t('tba.totalReward') }}</h3>
				<div class="cont">
					<div class="item">
						<span class="mainFontLightColor">{{ $t('tba.today') }}</span>
						{{ today_amount }}
					</div>
					<div class="item">
						<span class="mainFontLightColor">{{ $t('tba.total') }}</span>
						{{ total_amount }}
					</div>
				</div>
			</div>
			
			<div v-if="rankList && rankList.length">
			    <div class="rankingList" v-for="(item, index) in rankList" v-if="index >= 3" :key="index">
			        <span class="num">
						<span v-if="item.seq > 9">{{ item.seq }}</span>
						<span v-else>0{{ item.seq }}</span>
					</span>
					<!-- <div class="avatar"><img :src="item.icon" alt=""></div> -->
					<div class="avatar"><img src="../../../public/img/logo.jpeg" alt="" /></div>
					<div class="info">
						<div class="download"><img src="@/assets/imgs/rank/rankinglist_download.png" alt=""></div>
						<div class="userName mainFontColor">{{ item.username }}</div>
						<div class="reward mainFontLightColor">
							<span class="mainFontLighterColor">{{ $t('tba.today') }}</span>{{ item.amount }}
							<i></i>
							<span class="mainFontLighterColor">{{ $t('tba.total') }}</span>{{ item.tamount }}
						</div>
					</div>
			    </div>
			</div>
			<div v-else class="not-data">
			    <img class="bannerImg" src="@/assets/imgs/index/notData.png"/>
			    <p>暂无数据</p>
			</div>
		</div>
		
		
    </div>
</template>

<script>
	import commonHead from '@/components/commonHead';

    export default {
        name: "rankingList2",
        components: { commonHead },
        data() {
            return {
                dataList: [],
                phone: "", // 手机号
                sms: "", // 短信验证码
                flag: false, // 获取验证码 是否禁用
                count: 60, // 60s 倒计时
                timer: null, // 倒计时的 定时器
                sid: "", // 验证
                bindPhone: false,
                openid: "",
                email: "",
                rankList: [],
				// 20210415----------
				today_amount: 0,
				total_amount: 0,
            };
        },
        created() {
            this.getRankList();
        },
        methods: {
            // onClickLeft  点击返回“设置”页面
            onClickLeft() {
                this.$router.push("newHome");
            },

            getRankList() {
                this.$post2("Trade/api/index/ranklist", {})
                    .then((res) => {
                        console.log(res);
                        this.rankList = res.list;
						this.today_amount = res.today_amount
						this.total_amount = res.total_amount
                    })
                    .catch((e) => {
                        this.$toast.fail(e);
                    });
            },
        },
    };
</script>

<style lang="less" scoped>
    .rankingList2 {
		background: url(../../assets/imgs/rank/rankinglist_bg.png) no-repeat center top;
		background-size: 100% auto;
        .rankingList2Box {
            // padding: 0.3rem;
			// padding-top: 2.3rem;
            // border-bottom: 1px solid #f1f1f1;

            .userName {
                font-size: 0.29rem;
                // font-family: PingFang SC;
                font-weight: 500;
                color: #000000;
                padding-bottom: 0.1rem;
				position: relative;
				height: .52rem;
				&::before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100%);
				}
            }

            .rankItem {
                margin-top: 0.1rem;
                padding: 0 0.05rem;
                font-size: 0.25rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #666666;
                overflow: hidden;

                span {
                    float: right;
                }

            }

            .order {
                img {
                    height: 0.37rem;
                    margin-right: 0.1rem;
                    vertical-align: -0.1rem;
                }
            }

            .amount {
                span {
                    font-size: 0.27rem;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #F13333;
                }
            }
        }
    }

// ------20200410[start]------
.rankTop {
	padding-top: 2.3rem;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin: 0 .3rem .3rem;
	.item {
		color: #FFFFFF;
		font-size: .28rem;
		text-align: center;
		.icon {
			position: relative;
			width: 1.5rem;
			img {
				display: block;
			}
			.mask {
				position: relative;
				z-index: 2;
				width: 1.5rem;
			}
			.avatar {
				z-index: 1;
				width: 1.2rem;
				height: 1.2rem;
				border-radius: 50%;
				position: absolute;
				left: .14rem;
				top: .4rem;
			}
		}
		&.first {
			.icon {
				width: 2rem;
			}
			.mask {
				width: 2rem;
			}
			.avatar {
				width: 1.4rem;
				height: 1.4rem;
				left: .35rem;
				top: .48rem;
			}
		}
	}
}

.layoutBox {
	padding: .25rem .3rem;
	border-radius: .48rem .48rem 0 0;
}

.rankInfo {
	color: #373737;
	font-size: .28rem;
	line-height: .4rem;
	padding: .25rem .7rem;
	background: #FFFFFF;
	box-shadow: 0px 2px 18px 0px rgba(202, 202, 202, 0.3);
	border-radius: .12rem;
	margin-bottom: .3rem;
	h3 {
		font-size: .28rem;
		font-weight: bold;
		margin-bottom: .2rem;
	}
	.cont {
		display: flex;
		justify-content: space-between;
		.item {
			min-width: 2rem;
		}
	}
}

.rankingList {
	display: flex;
	align-items: center;
	margin-bottom: .2rem;
	.num {
		color: #D7D7DB;
		font-size: .4rem;
		font-family: Bahnschrift;
		margin-right: .25rem;
		span {
			font-weight: bold;
			font-style: italic;
		}
	}
	.avatar {
		margin-right: .125rem;
		img {
			width: .84rem;
			height: .84rem;
			border-radius: 50%;
			display: block;
		}
	}
	.info {
		flex-grow: 1;
		border-bottom: 1px solid #EDEDED;
		padding-top: .05rem;
		padding-bottom: .15rem;
	}
	.username {
		font-size: .28rem;
		line-height: .4rem;
		margin-bottom: .1rem;
	}
	.reward {
		font-size: .24rem;
		line-height: .35rem;
		i {
			width: 1px;
			height: .2rem;
			background: #F5F5F5;
			display: inline-block;
			margin: 0 .1rem;
		}
	}
	.download {
		float: right;
		padding-top: .3rem;
		img {
			width: .4rem;
			height: .4rem;
			display: block;
		}
	}
	&:last-child {
		.info {
			border-bottom: 0;
		}
	}
}

// ------20200410[start]------
</style>
